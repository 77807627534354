import { useRouter } from '#imports';

export type NavigateArgs = Parameters<ReturnType<typeof useRouter>['push']>[0];

export default function useNavigate() {
  const router = useRouter();

  async function navigate(...args: Parameters<typeof router.push>) {
    await router.push(...args);
  }

  return {
    navigate,
  };
}
